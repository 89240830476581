import React, {useContext, useState } from 'react';
import { Context } from '../../Context/Context.js';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeaturedImage from './FeaturedImage.js';
import './lightbox-styles.css';
import GooglePhoto from 'react-google-photo';

const GreyTextTypography = withStyles({
    root: {
      color: "#eeeeee",
      fontWeight: 'bold',
      cursor: 'pointer'
    }
  })(Typography);



const useStyles = makeStyles((theme) => ({
    featuredImage: {
        display: 'block',
        minWidth: '100%',
        width: '100%',
        height: '300px',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    },
    halfImage: {
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    },
    plusMore: {
        color: '#eeeeee'
    }
}));


export default function Gallery( {postMeta, featuredImage} ) {
    const classes = useStyles();
    const [state, setState] = useContext(Context);
    const [open, setOpen] = useState(false);
    const domain = process.env.REACT_APP_COM_COM_DOMAIN;


    const handleClose = () =>{
        setOpen(!open);
    }

    if(postMeta !== ''){
        let imageCount = postMeta.post_images.count;
        let images = postMeta.post_images.images;

        
        const imagesGallery = images.map( image => {
                    return {
                        src: image,
                        width: '1500',
                        height: '1000'
                    }
                }
            );
            
        

            
        

        const galleryOne = (image) => {
                return (
                    <div className={classes.featuredImage} style={{backgroundImage: 'url("'+image+'")'}}></div>
                )
        }

        const galleryTwo = (images) => {
            return (
                <Grid container style={{width: '100%', height: '300px'}} spacing={1}>
                    <Grid item xs={6}>
                        <div className={classes.featuredImage} style={{backgroundImage: 'url("'+images[0]+'")'}}></div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.featuredImage} style={{backgroundImage: 'url("'+images[1]+'")'}}></div>
                    </Grid>
                </Grid>
            )
        }

        const galleryThree = (images) => {
            return (
                <Grid container style={{width: '100%', height: '300px'}} spacing={1}>
                    <Grid item xs={6}>
                        <div className={classes.featuredImage} style={{backgroundImage: 'url("'+images[0]+'")'}}></div>
                    </Grid>
                    <Grid item xs={6} container  spacing={1} >
                        <Grid item xs={12} >
                            <div  className={classes.halfImage} style={{backgroundImage: 'url("'+images[1]+'")'}}>

                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className={classes.halfImage} style={{backgroundImage: 'url("'+images[2]+'")'}}>

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )
            
        }

        const galleryMore = (images) => {

            return (
                <React.Fragment>
                    <GooglePhoto
                        open={open}
                        src={imagesGallery}
                        onClose={handleClose}
                    />
                    <Grid container style={{width: '100%', height: '300px'}} spacing={1} onClick={handleClose}>
                        <Grid item xs={6}>
                            <div className={classes.featuredImage} style={{backgroundImage: 'url("'+images[0]+'")'}}></div>
                        </Grid>
                        <Grid item xs={6} container  spacing={1} justify="center" >
                            <Grid item xs={12} >
                                <div  className={classes.halfImage} style={{backgroundImage: 'url("'+images[1]+'")'}}>

                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.halfImage} style={{backgroundImage: 'url("'+images[2]+'")'}}>
                                    <Grid container direction="column" justify="center" style={{height: '100%'}} alignContent="center">
                                        <Grid item xs={3}>
                                            <GreyTextTypography variant="h5">{"+"+(imageCount-2)}</GreyTextTypography>
                                        </Grid>
                                    </Grid>
                                    
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
            
        }

        switch (imageCount){
            case 0: 
                return ( <FeaturedImage image={featuredImage} published='true'></FeaturedImage>)
            case 1:
                return (galleryOne(images[0]));
            case 2:
                return (galleryTwo(images));
            case 3:
                return (galleryThree(images));
            default:
                return (galleryMore(images));
        }

    } else {
        return null;
    }

    
}