import React, {useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Context } from '../../Context/Context.js';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostMeta from './PostMeta.js';
import FeaturedImage from './FeaturedImage.js';
import Gallery from './Gallery.js';

const PostCard = withStyles({
    root: {
      paddingBottom: "0px",
    }
  })(Card);

export default function Post( {post, content, index} ) {
    const [ postMeta, setPostMeta ] = useState('');
    const [ imageURL, setImageURL ] = useState('');
    const domain = process.env.REACT_APP_COM_COM_DOMAIN;



        /* Make API Call for post meta*/
        useEffect(() => {
            let ignore = false;
            async function fetchData() {
                let url = domain+'/wp-json/com-com/v1/get-post-meta';
                let body = {
                    post_id: post.id,
                }
                let formdata = new FormData();
                for (const property in body) {
                    formdata.append(property, body[property]);
                }
                const result = await axios.post(url, formdata, )
                if (!ignore) setPostMeta(result.data);
            }
    
            fetchData();
            return () => { ignore = true; }
        }, []);

        /* Make API Call for featured image id*/
        useEffect(() => {
            let ignore = false;
            async function fetchData() {
                let url = domain+'/wp-json/wp/v2/media/'+post.featured_media;
                let body = {
                    post_id: post.id,
                }
                let formdata = new FormData();
                for (const property in body) {
                    formdata.append(property, body[property]);
                }
                const result = await axios.get(url, formdata, )
                if (!ignore) setImageURL(result.data);
            }
    
            fetchData();
            return () => { ignore = true; }
        }, []);


    //get the featured image url
    return (
            <Grid 
                key={"post-card-"+index}
                item 
                sm={12} 
                md={4}
                lg={3}
                align="left"
                >
                <PostCard key={index}>
                    <CardContent>
                        <PostMeta postMeta={postMeta} ></PostMeta>
                        <Gallery
                            postMeta={postMeta}
                            featuredImage={imageURL}
                        ></Gallery>
                        <div dangerouslySetInnerHTML={{__html: content}} />
                    </CardContent>
                </PostCard>
            </Grid>
    )
}