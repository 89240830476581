import React, {useContext } from 'react';
import axios from 'axios';
import { Context } from '../../Context/Context.js';
import { Grid, Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookLogin from 'react-facebook-login';


    const useStyles = makeStyles((theme) => ({
        root: {
            background: '#A5C1C9',
            padding: '5px 30px',
            color: '#333'

        },
        menuIcon: {
            fontSize: '2em'
        }
        
    }));


  export default function Header( {setProfile, profile, WPUser, setWPUser, logo} ) {
    const classes = useStyles();
    const [state, setState] = useContext(Context);
    const domain = process.env.REACT_APP_COM_COM_DOMAIN;
     
    
    let FBClicked = () => {
        //silence
    }
    
    const onSuccess = (response) => {
        let WPUsername = response.name.replace(' ', "_");
        const profile = {
            name: response.name,
            email: response.email,
            photo: response.picture.data.url,
            loginService: 'facebook',
            WPUsername: WPUsername,
            password: response.accessToken,
        }
        localStorage.setItem('profile', JSON.stringify(profile));
    
        const url = domain+'wp-json/com-com/v1/registration';
        //create user
        axios.post(url)
            .then( (res) => {
                const salt = res.data['salt'];
                const key = 'aVdG#D.KRFXw)dr!37}BrpkxdQM8N4';
                const authHeader = salt+"_"+key;
                let formdata = new FormData();
                let body = profile;
                const headers = {
                    "X-Scholistit-Auth": authHeader,
                    "Content-Type": "multipart/form-data"
                }
                for (const property in body) {
                    formdata.append(property, body[property]);
                }
                //make 2nd call
                axios.post(url, formdata, {headers: headers})
                .then( (res) => {
                    let app_pass = res.data.com_com_key;
                    if(app_pass === 'undefined' || app_pass === null || app_pass === ''){
                        localStorage.setItem('WPProfile', JSON.stringify(res));
                        setWPUser(res.data);
                        let WPuser = res.data.user;
                        window.location.href = domain+'login-url/?user_login='+WPuser.user_login+"&com_com_key="+res.data.com_com_appid;
                    } else  {
                        localStorage.setItem('WPProfile', JSON.stringify(res.data));
                        setWPUser(res.data);
                        let WPuser = res.data.user;
                    }
                })
                
            });
     } 

    //get facebook sso response
    const responseFacebook = (response) => {
        setProfile(response);
        onSuccess(response);
      }


    return (
            <React.Fragment>
                <Grid 
                    container 
                    className={classes.root} 
                    justify="space-between" 
                    alignItems="center" 
                    alignContent="center"
                    >
                        <Grid item xs={9} className={classes.menuIcon} align="left">
                            <img 
                                style={{maxHeight: '50px', float: 'left', paddingRight: '15px'}}
                                src={state.logo}
                                className="custom-logo" 
                                alt=""
                                    />
                            <Typography variant="h6">{state.siteTitle}</Typography>
                            <Typography>{state.siteTagline}</Typography>

                        </Grid >
                          
                {/** end header grid */}   
                </Grid>
                {(profile.accessToken)
                    ? null
                    : <FacebookLogin
                    appId="437245837678279"
                    autoLoad={true}
                    fields="name,email,picture"
                    onClick={FBClicked}
                    callback={responseFacebook} 
                    cssClass="rrfm-button-class"
                    />
                }
                
            </React.Fragment> 
    )
    
}