import React, {useContext } from 'react';
import { Context } from '../../Context/Context.js';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoriesList from './CategoriesList.js';



const useStyles = makeStyles((theme) => ({
    root: {
        background: '#eeeeee',
        padding: '5px 30px',
        color: '#333'

    },
}));

const GreyTypography = withStyles({
    root: {
      color: "#bdbdbd"
    }
  })(Typography);

  const DkGreyTypography = withStyles({
    root: {
      color: "#424242"
    }
  })(Typography);


export default function PostMeta( {postMeta} ) {

    return(
    <React.Fragment>
        <Grid container justify="space-between">
            <Grid item xs={1}>
                <img src={postMeta.author_avatar} width="35px" height="auto" alt=""/>
            </Grid>
            <Grid item xs={5} style={{paddingLeft: "10px"}}>
                <Typography variant="subtitle2" >{postMeta.author_display_name}</Typography>
                <Typography variant="subtitle2" >{postMeta.date}</Typography>
            </Grid>
            <Grid item xs={5} align="right">
                {(postMeta.market_location !== 'undefined')
                    ? <DkGreyTypography variant="subtitle2">
                        {' '+postMeta.market_location+' '}
                      </DkGreyTypography>
                    : null
                }
            </Grid>  
        </Grid>
        <Grid container justify="space-between">
            <Grid item>
            {(postMeta.categories === 'undefined')
                ? null
                : <CategoriesList categories={postMeta.categories}></CategoriesList>
            }
            </Grid>
            <Grid item>
            {(postMeta.categories === [] || postMeta.categories === 'undefined' || postMeta.categories === null)
                ? null
                : <Box>
                    <DkGreyTypography variant="subtitle2">
                        <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                        {' '+postMeta.condition+' '}
                    </DkGreyTypography>
                  </Box>
            }
            </Grid>
        </Grid>
        
    </React.Fragment>
        )
}