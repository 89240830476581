import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Button } from '@material-ui/core';




const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh'
    },
    form: {
        width: '100%',
        textAlign: 'center',
        marginTop: '30vh'
    },
  }));


export default function TestCode( {codeRequested, phone, isAuth, setAuth} ) {
    const [state, setContext] = useContext(Context);
    const [secret, setSecret] = useState('')
    const [appLocation, setAppLocation] = useState('home');
    const [profile, setProfile] = useState({});
    const [WPUser, setWPUser] = useState({});
    const classes = useStyles();

    
    const changeAppLocation = (newLocation) => {
        console.log('clicked '+ appLocation);
        setAppLocation(newLocation);
    }

    const setLocalProfile = (newProfile) => {
        setProfile(newProfile);
    }

    const onEnterCode = (e) => {
        let code = e.target.value;
        setSecret(code);
    }

    const checkCode = () => {
        
            async function testSecret() {
            let url = 'https://hatterassailing.org/wp-json/community-sailing/v1/test-secret';
            let body = {
                billing_phone: phone,
                token: secret
            }
            let formdata = new FormData();
            for (const property in body) {
                formdata.append(property, body[property]);
            }
            const result = await axios.post(url, formdata, )
            if(result.data.validated === 'yes'){
                let CSC_auth = {
                    validated: 'yes',
                    user_id: '',
                    phone: phone
                }
                localStorage.setItem('community-sailing-auth', JSON.stringify(CSC_auth));
            }
            setAuth(result.data);
            
            };
            testSecret();
    }

    return(
        <React.Fragment>
            {(codeRequested === 'waiting for code' && isAuth !== 'yes')
                ? <React.Fragment>
                <input type="hidden" value={phone} />
                <TextField 
                    id="secret-code" 
                    variant="filled" 
                    label="secret code"
                    onChange={ (e) => onEnterCode(e) }
                    style={{marginBottom: '20px', width: '90%'}}
                    ></TextField>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={ (e) => (checkCode(e))}
                    >
                    Get Started
                </Button>
                </React.Fragment>
                : null
            }
        </React.Fragment>      
    );
}