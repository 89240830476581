import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../../Context/Context.js';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {Box, Button, Typography, TextField } from '@material-ui/core';
import TestCode from '../TestCode.js';
import GetCode from '../GetCode.js';
import BoardVote from './BoardVote.js';




const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh'
    },
    form: {
        width: '100%',
        textAlign: 'center',
    },
  }));


export default function MemberHome( {phone} ) {
    const [state, setContext] = useContext(Context);
    const [secret, setSecret] = useState('');
    const [isAuth, setIsAuth] = useState('');
    const [codeRequested, setCodeRequested] = useState('initial');
    const classes = useStyles();

    const setAuth = (result) => {
        if(result.validated === 'yes'){
            setIsAuth('yes');
        } else {
            setIsAuth('no');
        }
    }

    //use effect to get the registrations assigned to this billing phone number
    //show

   

    


    

    return(
        <Box
            display="flex"
            alignItems="center"
        >
            <div style={{width: '95%',  marginTop: '20vh'
}}>
                <div style={{textAlign: 'center', marginBottom: '30px'}}>
                    <img src="https://hatterassailing.org/wp-content/uploads/2020/09/cropped-logo-03-1.png" alt="" />
                </div>
            {(isAuth === 'no')
                ?<React.Fragment>
                    <Typography style={{textAlign: 'center'}} variant="h5">Uh Oh! That secret code didn't match.</Typography>
                    <Typography variant="h5">Please click to try again</Typography>
                    <Button onClick={window.location.reload()}>Try Again</Button>
                </React.Fragment>
                : null
            }
            {(isAuth === 'yes')
                ? <BoardVote phone={phone} ></BoardVote>
                : <form className={classes.form}>
                    <GetCode 
                        phone={phone} 
                        codeRequested={codeRequested} 
                        setCodeRequested={setCodeRequested}>
                    </GetCode>
                    <TestCode codeRequested={codeRequested} phone={phone} isAuth={isAuth} setAuth={setAuth}></TestCode>
                </form>
            } 
            </div>
                
        </Box>
    );
}