import React, {useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Context } from '../../Context/Context.js';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Post from './Post.js';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5px 0px',
        color: '#333',
    },
}));


export default function Feed( {posts} ) {
    const classes = useStyles();
    const [state, setState] = useContext(Context);

    function createMarkup(postContent) {
        return {__html: postContent};
    }
    
    return(

            <Grid 
            container 
            spacing={3}
            justify="center"
            className={classes.root}>
                {posts.map ( (post, index) => {
                    let content = post.excerpt.rendered;
                    return (
                    <Post 
                        key={index}
                        post={post}
                        content={content}
                        index={index}
                        ></Post>)             
                })}
        </Grid>
        
    )
}