import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, TextField } from '@material-ui/core';


export default function GetCode( { phone, codeRequested, setCodeRequested }) {
    const [billingPhone, setBillingPhone] = useState(phone);

    
    const handleChangePhone = (e) => {
        setBillingPhone(e.target.value)
    }

    const getCode = () => {
        async function testSecret() {
        let url = 'https://hatterassailing.org/wp-json/community-sailing/v1/get-secret';
        let body = {
            billing_phone: billingPhone,
            secret_type: 'member_vote'
        }
        let formdata = new FormData();
        for (const property in body) {
            formdata.append(property, body[property]);
        }
        const result = await axios.post(url, formdata, )
         if(result.data.twilio_response){
            setCodeRequested('waiting for code');
         } else {
             setCodeRequested('error');
         }
        };
        testSecret();
    }

    return(
        <React.Fragment>
            {(codeRequested === 'error')
                ? <Typography>It seems your phone number is not in our system. If you believe you've reached this message in error, please contact Hatteras Sailing directly.</Typography>
                : null
            }
            {(codeRequested === 'initial')
                ? <React.Fragment>
                    {(typeof(phone) === 'undefined')
                        ? <TextField id="phoneNumber" variant="outlined" label="enter your phone number" onChange={ (e) => handleChangePhone(e) }></TextField>
                        : null
                    }
                    <Typography gutterBottom={true} style={{marginBottom: '30px', width: '60%', textAlign: 'center', margin: '0 auto'}} variant="h5">
                        Click to get a secret code via text message
                        </Typography>
                    <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={ (e) => getCode(e) }
                    style={{marginTop: '20px'}}
                    >
                    Get A Passcode
                </Button>
                </React.Fragment>
                : null
            }
        </React.Fragment>
        
    );
}