import React, {useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Ballot from './Ballot.js'



const useStyles = makeStyles((theme) => ({
    featuredImage: {
        display: 'block',
        minWidth: '100%',
        width: '100%',
        height: '300px',
        backgroundSize: 'cover',
        backgroundImage: 'url("https://fairhaven-umc.com/wp-content/uploads/2020/11/20201130_094345-scaled.jpg")',
    },
    
    
}));


export default function BoardVote( {phone} ) {
    const [hasVoted, setHasVoted] = useState( 'false');
    const domain = process.env.REACT_APP_COM_COM_DOMAIN;

    const updateHasVoted = (value) => {
        setHasVoted(value);
    }

    const openWebsite = (e) => {
        window.location.href = "https://hatterassailing.org";
    }

    return(
        <React.Fragment>
            {(hasVoted !== 'cast')
                ? <Ballot phone={phone} hasVoted={hasVoted} updateHasVoted={updateHasVoted}></Ballot>
                : <div style={{textAlign: 'center'}}>
                    <Typography variant="h6">You have voted</Typography>
                    <Typography >Thank you for voting for the Hatteras Sailing Organization Board.</Typography>
                    <Button variant="contained" color="primary" onClick={(e) => openWebsite(e)}>Exit Voting</Button>
                </div>
            }
           
        </React.Fragment>
    )

}