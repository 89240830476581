import React, {useContext, useState } from 'react';
import axios from 'axios';
import { Button, FormHelperText, FormGroup, Checkbox, Container, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';







export default function Ballot( {phone, hasVoted, updateHasVoted} ) {
    const [vote, setVote ] = useState( {
        chairman: null,
        viceChair: null,
        secretary: null,
        treasurer: null
    } );
    const [memLarge, setMemLarge] = useState ( [] );
    const domain = process.env.REACT_APP_COM_COM_DOMAIN;


    function arrayUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    let handleChange = (event) =>{
        let board = vote;
        if('chairman' === event.target.name){
            board.chairman = event.target.value;
        }
        if('viceChair' === event.target.name){
            board.viceChair = event.target.value;
        }
        if('secretary' === event.target.name){
            board.secretary = event.target.value;
        }
        if('treasurer' === event.target.name){
            board.treasurer = event.target.value;
        }
        setVote(board)
    }

    let handleChangeCheck = (event) => {
        let newMem = event.target.name;
        let growingMems = memLarge;
        let index = growingMems.indexOf(newMem)
        if(index !== -1){
            growingMems.splice(index, 1);
        } else {
            growingMems.push(newMem)
        }
        growingMems = growingMems.filter(arrayUnique);
        setMemLarge(growingMems);
      };

    const error = () =>{
        let message = '';
        if(memLarge.length < 3 ) {
            message = "please choose three"
        } 
        if(memLarge.length > 3 ) {
            message = "please choose only three"
        } 
        if(memLarge.length === 3 ) {
            message = ""
        }
        return message;
    }

    const onFormSubmit = (event) => {
        event.preventDefault();
    
        let url = domain+'wp-json/community-sailing/v1/board-vote';
        let members = JSON.stringify(memLarge);
        
        let body = {
            billing_phone: phone,
            chairman: vote.chairman,
            viceChair: vote.viceChair,
            treasurer: vote.treasurer,
            secretary: vote.secretary,
            members: members
        }


        let formdata = new FormData();                     
        const headers = {
            "Content-Type": "multipart/form-data"
        }
        for (const property in body) {
            formdata.append(property, body[property]);
        }
        //make call
        axios.post(url, formdata, {headers: headers})
        .then( (res) => {
            updateHasVoted(res.data.vote);
        })
        

    }

   


    return(
        <Container>
            <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom={true}>Elect Our Board</Typography>
            <Grid container justify="center" style={{border: '1px solid #eeeeee'}}>
                <Grid item xs={12} sm={4} >
                    <form onSubmit={(e) => onFormSubmit(e)}>
                        <Typography variant="h5">Chairman</Typography>
                        <Typography>
                            Director of the organization Board of Directors and Committees.. Chairperson should nurture and direct the organization towards its goals and objectives as best possible. This includes delegating to others and teaching them how to accomplish the many objectives and tasks needed to make a strong, healthy, financially stable, inclusive, and constantly growing organization.
                        </Typography>
                        <FormControl component="fieldset">
                        <RadioGroup aria-label="chairman" name="chairman"  onChange={ (e) => handleChange(e) } >
                            <FormControlLabel value="Jay Phillips" control={<Radio required />} label="Jay Phillips" />
                        </RadioGroup>
                        </FormControl>

                        <div style={{padding: '30px'}}></div>
                        <Typography variant="h5">Vice Chairman</Typography>
                        <Typography>
                            Assist the Chairperson and Board with whatever is needed and be prepared at anytime to fill in or back up the Chairperson in the event of illness, travel commitments, or anything that might take him/her away from his duties.                        </Typography>
                        <FormControl component="fieldset">
                        <RadioGroup aria-label="vice chairman" name="viceChair" onChange={ (e) => handleChange(e) }>
                            <FormControlLabel value="Coach Bob" control={<Radio required />} label="Coach Bob" />
                        </RadioGroup>
                        </FormControl>

                        <div style={{padding: '30px'}}></div>
                        <Typography variant="h5">Secretary</Typography>
                        <Typography>
                            Keep records of Board Meetings, Membership Rosters, Club Directory, Update Content on the website as directed by the Board or Chairman, especially consent and liability waivers                        </Typography>
                        <FormControl component="fieldset">
                        <RadioGroup aria-label="secretary" name="secretary" onChange={(e) => handleChange(e) }>
                            <FormControlLabel value="Amy Hakola" control={<Radio required />} label="Amy Hakola" />
                        </RadioGroup>
                        </FormControl>

                        <div style={{padding: '30px'}}></div>
                        <Typography variant="h5">Treasurer</Typography>
                        <Typography>
                            Good knowledge of accounting principles. Helping the organization keep its non profit status and meet all IRS Filing Deadlines with the assistance of a paid accounting or CPA Firm. Special Attention to nurturing membership, fundraising ideas, and delegation to people who can help achieve these things. Treasurer should help select effective Fundraising and Membership Directors                        </Typography>
                        <FormControl component="fieldset">
                        <RadioGroup aria-label="treasurer" name="treasurer" onChange={ (e) => handleChange(e) }>
                            <FormControlLabel value="Chip Hammack" control={<Radio required/>} label="Chip Hammack" />
                        </RadioGroup>
                        </FormControl>

                        <div style={{padding: '30px'}}></div>
                        <Typography variant="h5">Members At Large</Typography>
                        <Typography gutterBottom={true}>
                            There are 3 additional members per the IRS recommendation for Non Profit Boards. These members help balance the votes and decide on important policy decisions for the organization and should have some useful skills and experience to add to the wisdom and knowledge of the Board of Directors.                        </Typography>
                        <FormGroup>
                            <FormHelperText style={{fontWeight: 'bold', color: 'red'}}>{error()}</FormHelperText>

                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Erin Vetter" />}
                                label="Erin Vetter"
                            />
                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Geoff Grisham" />}
                                label="Geoff Grisham"
                            />
                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Larry McClanahan" />}
                                label="Larry McClanahan"
                            />
                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Jay Crawford" />}
                                label="Jay Crawford"
                            />
                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Tater Gaskins" />}
                                label="Tater Gaskins"
                            />
                            <FormControlLabel
                                control={<Checkbox  onChange={ (e) => handleChangeCheck(e) } name="Nathan Snead" />}
                                label="Nathan Snead"
                            />
                        </FormGroup>
                        <div style={{padding: '30px'}}></div>

                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>



                    </form>
                </Grid>
            </Grid>
        </Container>
       
    )

}