import React, {useContext } from 'react';
import { Context } from '../../Context/Context.js';
import { Grid, Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

    const useStyles = makeStyles((theme) => ({
        root: {
            background: 'none',
            padding: '5px 30px 15px 30px',
            color: '#ccc'

        },
        menuIcon: {
            fontSize: '2em',
        }
        
    }));


  export default function HeaderMenu( {setAppLocation, appLocation, phone} ) {
    const classes = useStyles();

 
        return (
                <Grid 
                    container 
                    className={classes.root} 
                    justify="center" 
                    alignItems="center" 
                    alignContent="center"
                    style={{boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',}}
                    >
                        <Grid item xs={4} className={classes.menuIcon} align="center">
                                <FontAwesomeIcon 
                                    icon="home" 
                                    className={(appLocation === 'home') ? "selected" : ''} 
                                    onClick={  () => setAppLocation('home')  } 
                                    ></FontAwesomeIcon>  
                            </Grid>
                            
                        <Grid item xs={4} className={classes.menuIcon} align="center">
                            <FontAwesomeIcon 
                                icon="person-booth" 
                                className={(appLocation === 'boardVote') ? "selected" : ''} 
                                onClick={  () => setAppLocation('boardVote')  } 
                                ></FontAwesomeIcon>  
                        </Grid>
                           
                             
            </Grid>
        )
    
}