import React from 'react';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    featuredImage: {
        display: 'block',
        minWidth: '100%',
        width: '100%',
        height: '300px',
        backgroundSize: 'cover',
        backgroundImage: 'url("https://fairhaven-umc.com/wp-content/uploads/2020/11/20201130_094345-scaled.jpg")',
    },
    
    
}));


export default function FeaturedImage( {image, published} ) {
    const classes = useStyles();

    if(image !== '' && published == 'true'){

        let imageUrl = image.guid.rendered;
        
        return (
            <React.Fragment>
                <div className={classes.featuredImage} style={{backgroundImage: 'url("'+imageUrl+'")', width: '100%'}}></div>
            </React.Fragment>
        )
    } else if(image !== '' && published == 'false') {

        return (
            <React.Fragment>
                <div className={classes.featuredImage} style={{backgroundImage: 'url("'+image+'")'}}></div>
            </React.Fragment>
        ) 
        
    } else {
        return null;
    }


    
    
   
}