import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Context } from '../../Context/Context.js';
import Header from '../Components/Header.js';
import HeaderMenu from '../Components/HeaderMenu.js';
import Main from '../Components/Main.js';




const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        width: '100vw',
        minHeight: '100vh',
        maxHeight: 'none',
        justifyContent: 'center',
        maxWidth: 'none !important',
        overflow: 'auto',
        margin: '0',
    },
  }));


export default function MemberVote() {
    const [state, setContext] = useContext(Context);
    const [appLocation, setAppLocation] = useState('boardVote');
    const [profile, setProfile] = useState({});
    const [WPUser, setWPUser] = useState({});

    const {phone} = useParams();


    const classes = useStyles();
    
    const changeAppLocation = (newLocation) => {
        console.log('clicked '+ appLocation);
        setAppLocation(newLocation);
    }

    const setLocalProfile = (newProfile) => {
        setProfile(newProfile);
    }


    

    return(
            <React.Fragment>
                <Main 
                    phone={phone}
                    class={classes.root}
                    appLocation={appLocation}
                    >{appLocation}</Main>
            </React.Fragment>
            
        
    );
   


}