import React, {useContext } from 'react';
import { Context } from '../../Context/Context.js';
import { makeStyles } from '@material-ui/core/styles';
import Feed from './Feed.js';
import MemberHome from './Members/MemberHome.js';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#c3e8f4',
        position: '0',
        padding: '5px 30px',
        color: '#333'

    },
    menuIcon: {
        fontSize: '2em'
    }
    
}));


export default function Main( {appLocation, phone} ) {
const classes = useStyles();
const [state, setState] = useContext(Context);

return(
    <React.Fragment>
        {(appLocation === 'home')
            ? <Feed posts={state.posts}></Feed>
            : null
        }
        {(appLocation === 'boardVote')
            ? <MemberHome phone={phone}></MemberHome>
            : null
        }
    </React.Fragment>
);


}