import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPersonBooth, faArrowCircleRight, faImage, faStar, faGift, faUsers, faComment, faTags, faMapMarker, faVolumeUp, faFileImage, faMagic, faLifeRing, faArrowsAltV, faCheck, faGrinBeamSweat, faBell, faHeart, faCopy, faEnvelope, faSave, faExclamationTriangle, faTrashAlt, faEllipsisH, faWindowClose, faThumbsUp, faCommentAlt, faShare, faBars, faSearch, faDoorOpen,faEye, faQuestionCircle, faUser, faPlusSquare, faMinusSquare, faCalendarAlt, faCalendarDay, faHouseUser, faSchool, faHome, faSignInAlt, faChalkboard, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import {  ThemeProvider, createMuiTheme, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';
import { blue, blueGrey} from '@material-ui/core/colors';
import { CssBaseline, Container } from '@material-ui/core';
import {  ContextController } from "./Context/Context.js";
import './App.css';
import MemberVote from './Components/Pages/MemberVote.js';




library.add( faPersonBooth, faArrowCircleRight, faImage, faStar, faGift, faUsers, faComment, faTags, faMapMarker, faVolumeUp, faFileImage, faMagic, faLifeRing, faArrowsAltV, faCheck, faGrinBeamSweat, faBell, faHeart, faFacebookSquare, faCopy, faEnvelope, faSave, faExclamationTriangle, faTrashAlt, faEllipsisH, faWindowClose, faThumbsUp, faCommentAlt, faShare, faBars, faSearch, faDoorOpen, faEye, faQuestionCircle, faUser, faPlusSquare, faMinusSquare, faCalendarAlt, faCalendarDay, faHouseUser, faSchool, faHome, faSignInAlt, faChalkboard, faGlobeAmericas);


let theme = createMuiTheme({
  typography: {
    fontFamily: 'Quicksand, Arial',
    fontWeight: '500'
    
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Quicksand',
      },
    },
    
  },
  palette: {
    primary: blue,
    secondary: blueGrey,
  },
  
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(() => ({
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
  }
}));

function App() {
  const classes = useStyles();


  return (
      <ContextController>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container  
            maxWidth={false}
            className={classes.root}
            >
            <Router>
                <Switch>
                    <Route exact path="/members/:phone/" component={MemberVote} />
                  </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      </ContextController>    
  )
}

export default App;
