import React, { useState, useEffect } from 'react';
import axios from 'axios';


export const Context = React.createContext();

export function ContextController({children}){

    const objsEqual = (a, b) => {
        delete a.key;
        delete b.key;
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {
            return false;
        }
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    }

    
    let profile;
    // Initial state
    let initialState = {
        siteUrl: 'https://fairhaven-umc.com',
        facebookProfile: '',
        googleApisKey: '',
        appLocation: '',
        wpUsername: '',
        wpApplicationPassword: '',
        posts: [],
        marketLocations: [],
        objsEqual
     }

     //set the initial state into a use State
     const [state, setContext] = useState(initialState);

     useEffect ( () => {
        
        
        //go out to the api
        const promises = [];
        let params = {
            per_page: 100,
            orderby: 'count',
            order: 'desc'
        }
        //MEGDO: need to loop through paginated results as this thing grows...here we are only getting the first page. which is BAD
        let domain = process.env.REACT_APP_COM_COM_DOMAIN
        //promises.push(axios.get(domain+'wp-json/wp/v2/posts'));
        //promises.push(axios.get(domain+'wp-json/wp/v2/categories?hide_empty=false&_fields=id,name'))
        //promises.push(axios.get(domain+'wp-json/com-com/v1/logo'));
        //promises.push(axios.get(domain+'wp-json/'));





       
       
    
        Promise.all(promises).then( res => {
            //let posts =  res[0].data
           // let categories = res[1].data
           // let logo = res[2].data.site_icon
           // let name = res[3].data.name
           // let description = res[3].data.description
            

            
            setContext( {
                //logo: logo,
                //posts: posts,
               // categories: categories,
                profile: {},
                googleApisKey: 'AIzaSyCJJyK6CsHjz_urLNekKFVu1nQMPDObJME',
                appLocation: 'home',
               // siteTitle : name,
                //siteTagline: description
            })

        });

     }, []);


    return(
        <Context.Provider value={[state, setContext]}>{children}</Context.Provider>
    )

}