import React from 'react';
import { Typography } from '@material-ui/core';
import {  withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





  const DkGreyTypography = withStyles({
    root: {
      color: "#424242"
    }
  })(Typography);


export default function CategoriesList( {categories} ) {
    if(categories){
        //MEGTODO: remove last comma
        return(
            <React.Fragment>
                <DkGreyTypography variant="subtitle2">
                    <FontAwesomeIcon icon="tags"></FontAwesomeIcon> 
                    {categories.map ( (category, index) => {
                        return(
                            <span key={index}> {category},</span>
                            )
                        })
                    }
                </DkGreyTypography>
            </React.Fragment>
        )
    } else {
        return null;
    }
    
    
}